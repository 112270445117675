export enum ProductLineFloatTemplateSearchTypes {
    CustomerId = "CustomerId",
    CustomerName = "CustomerName",
    ProductLineNumber = "ProductLineNumber",
    ProductLineName = "ProductLineName",
    ProductLineCategoryNumber = "ProductLineCategoryNumber",
    ContractNumber = "ContractNumber",
    ContractName = "ContractName",
    TemplateId = "TemplateId"
};
