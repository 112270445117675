import { Helmet } from 'react-helmet-async';
import { Suspense, useMemo, useReducer, useState } from 'react';
import { ExcelTemplateTypes, 
         ProductLineFloatTemplateSearchTypes, 
         SpecialFixedTemplateSearchTypes, 
         SupplierTemplateSearchTypes, 
         TemplateStatus } from 'enums';
import { useCosmosExcelConverterService, useTemplateHistoryService } from 'services';
import { TemplateButton, TemplateHistoryErrorPanel, TemplateHistoryHeader, 
         TemplateHistorySearchBar, 
         TemplateHistorySearchResultsTable, 
         TemplateSelector,
         LoadingAnimation } from 'components';
import { SupplierPricingTemplateHistorySearch, 
         SpecialFixedTemplateHistorySearch, 
         PricingTemplateHistoryApiResult,
         TemplateHistoryApiResultObject,
         TemplateHistorySearchParameters,
         ProductLineFloatTemplateHistorySearch, 
         SupplierPricingTemplateHistoryApiResultObject,
         SupplierPricingTemplateMissingItemsApiRequest} from 'types';
import { prettyStringify } from 'utils';
import { updateSearchResults, TemplateHistorySearchResults_Default } from "./helpers/updateSearchResults";
import { HistoryApiMethods, searchHistoryApi } from './helpers/searchHistoryApi';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { useAsyncTransition } from 'hooks';
import "./TemplateHistoryPage.scss";

export default function TemplateHistoryPage() {  
    const [searchResults, dispatch] = useReducer(updateSearchResults, TemplateHistorySearchResults_Default),
          [templateType, setTemplateType] = useState<ExcelTemplateTypes>(ExcelTemplateTypes.None),
          [isSearching, startSearching] = useAsyncTransition(),
          [isDownloading, startDownloading] = useAsyncTransition(),
          renderSearchResultSection = (searchResults.emptyResult || searchResults.result?.length > 0) && !isSearching,
          showLoadMoreButton = searchResults.hasMoreResults,
          previousSearch = searchResults.previousSearch && { ...searchResults.previousSearch },
          hasValidPreviousSearch = !!(previousSearch?.searchType && 
                                      previousSearch?.searchTerms && 
                                      previousSearch?.templateStatus);

    const { getSupplierPricingTemplateHistory,
            getProductLineFloatPricingTemplateHistory, 
            getSpecialFixedPricingTemplateHistory,
            getMissingProductData } = useTemplateHistoryService(),
          { convertSupplierPricingTemplate,
            convertSpecialFixedTemplate,
            convertProductLineFloatTemplate } = useCosmosExcelConverterService(),
          { convertSupplierPricingTemplateMissingItems } = useCosmosExcelConverterService();

    const loadAnimation = useMemo(() => {
        return (
            <div className="templateLoaderContainer">
                <span className="templateLoader"></span>
            </div>
        );
    }, []);

    const defaultOperations = useMemo(() => {
        return { transitionStarter: startSearching,
                 reducer: dispatch } as HistoryApiMethods;
    }, [startSearching, dispatch]);

    const handleSearch = async (searchType: string, searchPage: number, rawTerms: string, templateStatus: TemplateStatus) => {
        const searchTerms = rawTerms.split(',').map(x => x.trim()),
              params = { searchType: searchType,
                         searchPage: searchPage,
                         searchTerms: searchTerms,
                         templateStatus: templateStatus } as TemplateHistorySearchParameters,
              operations = { ...defaultOperations };
        
        switch(templateType) {
            case ExcelTemplateTypes.Supplier:
                operations.makeApiCall = getSupplierPricingTemplateHistory;
                await searchHistoryApi(params, SupplierTemplateSearchTypes, operations).catch(ex => {
                    throw Error(`${templateType} search operation failed, reason: ${ex.toString()}`);
                });
                break;
            case ExcelTemplateTypes.FloatProductLine:
                operations.makeApiCall = getProductLineFloatPricingTemplateHistory;
                await searchHistoryApi(params, ProductLineFloatTemplateSearchTypes, operations).catch(ex => {
                    throw Error(`${templateType} search operation failed, reason: ${ex.toString()}`);
                });
                break;
            case ExcelTemplateTypes.Fixed:
                operations.makeApiCall = getSpecialFixedPricingTemplateHistory;
                await searchHistoryApi(params, SpecialFixedTemplateSearchTypes, operations).catch(ex => {
                    throw Error(`${templateType} search operation failed, reason: ${ex.toString()}`);
                });
                break;
            default:
                throw Error(`Template history for ${templateType} is not yet implemented.`);
        }
    };

    const handleDownloadExcelDocument = async (templateType: ExcelTemplateTypes, templateId: string) => {
        let params: object | undefined,
            action: (params: any) => Promise<PricingTemplateHistoryApiResult>,
            excelConverter: (document: TemplateHistoryApiResultObject) => void;
        
        switch (templateType) {
            case ExcelTemplateTypes.Supplier:
                params = { searchType: SupplierTemplateSearchTypes.TemplateId,
                           searchPage: 1,
                           searchTerms: [templateId],
                           templateStatus: TemplateStatus.Any } as SupplierPricingTemplateHistorySearch;
                action = getSupplierPricingTemplateHistory;
                excelConverter = convertSupplierPricingTemplate;
                break;
            case ExcelTemplateTypes.Fixed:
                params = { searchType: SpecialFixedTemplateSearchTypes.TemplateId,
                           searchPage: 1,
                           searchTerms: [templateId],
                           templateStatus: TemplateStatus.Any } as SpecialFixedTemplateHistorySearch;
                action = getSpecialFixedPricingTemplateHistory;
                excelConverter = convertSpecialFixedTemplate;
                break;
            case ExcelTemplateTypes.FloatProductLine:
                params = { searchType: ProductLineFloatTemplateSearchTypes.TemplateId,
                           searchPage: 1,
                           searchTerms: [templateId],
                           templateStatus: TemplateStatus.Any } as ProductLineFloatTemplateHistorySearch;
                action = getProductLineFloatPricingTemplateHistory;
                excelConverter = convertProductLineFloatTemplate;
                break;
            case ExcelTemplateTypes.FloatItem:
            default:
                throw Error(`Unsupported template type: ${templateType}`);
        }

        if (!action)
            throw Error("handleDownloadExcelDocument: undefined action, this is a bug and should be reported");
        else if (!excelConverter)
            throw Error("handleDownloadExcelDocument: undefined excelConverter, this is a bug and should be reported");

        const apiResult = await action(params).catch(ex => {
            throw Error(`handleDownloadExcelDocument.action: ` + 
                        `params: ${prettyStringify(params)}, error: ${ex.toString()}`);
        });

        if (apiResult.error) {
            dispatch({ type: "downloadError",  apiResult: apiResult });
        } else if (apiResult.response?.Results?.length) {
            excelConverter(apiResult.response.Results[0]);
        }
    };

    const handleDownloadMissingItemsDocument = async (templateId: string) => {
        await startDownloading(async () => {
            const params = {
                searchType: SupplierTemplateSearchTypes.TemplateId,
                searchTerms: [templateId],
                templateStatus: TemplateStatus.Any
            } as SupplierPricingTemplateHistorySearch;

            const apiResult = await getSupplierPricingTemplateHistory(params).catch(ex => {
                throw Error(`useTemplateHistoryService.getSupplierPricingTemplateHistory: ` +
                            `params: ${prettyStringify(params)}, error: ${ex.toString()}`);
            });

            if (apiResult.error) {
                dispatch({ type: "downloadError", apiResult: apiResult });
            } 
            else if (apiResult.response?.Results?.length) {
                const resultObject = apiResult.response.Results[0] as SupplierPricingTemplateHistoryApiResultObject,
                      missingItems = resultObject.MissingItems ?? [];

                //Verify that these are numbers and not strings
                const missingItemRequest = missingItems.flatMap(x => {
                    const itemNumbers = x.ItemNumbers?.map(item => parseInt(`${item}`)) ?? [],
                          productLine = parseInt(x.ProductLine ? (`${x.ProductLine}`) : "0");
                    return [{ ProductLine: productLine, 
                              ItemNumbers: itemNumbers }] as SupplierPricingTemplateMissingItemsApiRequest
                });
                
                const missingProductPriceFxData = await getMissingProductData(missingItemRequest).catch((ex: unknown) => {
                    const errorMessage = ex instanceof Error ? ex.message : String(ex);
                    throw new Error(`useTemplateHistoryService.getMissingProductData error: ${errorMessage}`);
                });

                convertSupplierPricingTemplateMissingItems(missingProductPriceFxData);
            }
        }).catch(ex => {
            throw new Error(`handleDownloadMissingItemsDocument: ${ex.toString()}`);
        });
    };

    const handleTemplateTypeChange = (selection: ExcelTemplateTypes) => {
        dispatch({ type: "reset" });
        //Delay to allow the result table to fade out, prevents a jarring transition
        setTimeout(() => setTemplateType(selection), 525);
    };

    return (
        <>
            <Helmet>
                <title>Pricing Portal | Template History</title>
            </Helmet>
            {isDownloading && <LoadingAnimation render={isDownloading} />}
            <div className="templateHistoryPage">
                <TemplateHistoryErrorPanel shouldRender={searchResults.errors?.length > 0} 
                                           content={searchResults.errors ?? []} 
                                           actionMessage={searchResults.actionMessage ?? ""} 
                                           dismissCallback={() => dispatch({ type: "dismissErrors" })}/>
                <div className="historyHeaderRow">
                    <div className="historyHeaderTextContainer">
                        <TemplateHistoryHeader templateType={templateType} searchInProgress={isSearching} hasSearchResult={searchResults.result?.length > 0} />
                    </div>
                    <TemplateSelector className="templateHistorySelect"
                                      selected={templateType}
                                      onTemplateChange={(x) => handleTemplateTypeChange(x)} />
                </div>
                <div className="historySearchBarContainer">
                    <TemplateHistorySearchBar shouldRender={templateType !== ExcelTemplateTypes.None} 
                                              templateType={templateType}
                                              page={searchResults.page}
                                              isSearching={isSearching}
                                              onSearchStart={
                                                async (type, page, terms, templateStatus) => 
                                                    await handleSearch(type, page, terms, templateStatus)
                                              }/>
                </div>
                {(isSearching ? (
                    loadAnimation
                    ) : (
                        <Suspense fallback={loadAnimation}>
                            {showLoadMoreButton && (
                                <div className={`extraSearchResultOptions`}>
                                    <p>More results are available for this search</p>
                                    <TemplateButton 
                                        disabled={isSearching}
                                        text={"LOAD MORE"}
                                        btnIcon={faArrowDown}
                                        onClick={async () => {
                                            if (hasValidPreviousSearch)
                                                return await handleSearch(previousSearch.searchType, 
                                                                          searchResults.page,
                                                                          previousSearch.searchTerms.join(','), 
                                                                          previousSearch.templateStatus ?? -1);
                                            }
                                        }
                                    />
                                </div>
                            )}
                            <div className='historySearchResultsContainer'>
                                <TemplateHistorySearchResultsTable shouldRender={renderSearchResultSection}
                                                                   templateType={templateType}
                                                                   isComplete={!searchResults.hasMoreResults}
                                                                   onDownload={handleDownloadExcelDocument}
                                                                   onDownloadMissingItems={handleDownloadMissingItemsDocument}
                                                                   tableData={searchResults.result ?? []} />
                            </div>
                        </Suspense>
                    )
                )}
            </div>
        </>
    );
}
