import { ExcelTemplateTypes, SpecialFixedTemplateSearchTypes } from "enums";
import { useInOutTransition } from "hooks";
import SearchRadioInput from "./SearchRadioInput";

interface Props {
    searchType: string,
    updateHandler: (x: SpecialFixedTemplateSearchTypes) => void,
    selectedTemplateType: ExcelTemplateTypes
}

export default function SpecialFixedTemplateSearchOptions(props: Props) {
    const { searchType, updateHandler, selectedTemplateType } = props,
          shouldRender = selectedTemplateType === ExcelTemplateTypes.Fixed,
          { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender, 0, 275);
    return (isNotTransitionedOut && 
        <>
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="customerId" 
                              labelText="Customer Id"
                              checkedCondition={searchType === SpecialFixedTemplateSearchTypes.CustomerId}
                              onClick={() => updateHandler(SpecialFixedTemplateSearchTypes.CustomerId)} />
            
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="customerName" 
                              labelText="Customer Name" 
                              checkedCondition={searchType === SpecialFixedTemplateSearchTypes.CustomerName}
                              onClick={() => updateHandler(SpecialFixedTemplateSearchTypes.CustomerName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="itemNumber" 
                              labelText="Item Number" 
                              checkedCondition={searchType === SpecialFixedTemplateSearchTypes.ItemNumber}
                              onClick={() => updateHandler(SpecialFixedTemplateSearchTypes.ItemNumber)} />
            
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="contractNumber" 
                              labelText="Contract Number" 
                              checkedCondition={searchType === SpecialFixedTemplateSearchTypes.ContractNumber}
                              onClick={() => updateHandler(SpecialFixedTemplateSearchTypes.ContractNumber)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="contractName" 
                              labelText="Contract Name" 
                              checkedCondition={searchType === SpecialFixedTemplateSearchTypes.ContractName}
                              onClick={() => updateHandler(SpecialFixedTemplateSearchTypes.ContractName)} />
        </>
    );
}
