import { SupplierPricingTemplateHistoryApiResponseDataRow, 
         SupplierPricingExcelTemplateHeaderRows_AoA, 
         SpecialFixedTemplateHeaderRows_AoA,
         SpecialFixedTemplateHistoryApiResponseDataRow,
         SpecialFixedTemplateHistoryApiResultObject,
         SupplierPricingTemplateHistoryApiResultObject,
         TemplateHistoryApiResultObject,
         ProductLineFloatTemplateHistoryApiResultObject,
         ProductLineFloatTemplateHistoryApiResponseDataRow,
         ProductLineFloatTemplateHeaderRows_AoA, 
         SupplierPricingTemplateMissingItemsApiResponse} from "types";
import { makeDeepCopy, commonRegex, dateFormatter} from "utils";
import * as XLSX from "xlsx";

export function useCosmosExcelConverterService() {
    const convertSupplierPricingTemplate = (document: TemplateHistoryApiResultObject) => {
        const cosmosDocument = document as SupplierPricingTemplateHistoryApiResultObject,
              { FileName: fileName, Data: data, Created: creationDate } = cosmosDocument,
              outFileName = generateExcelFileName(fileName ?? "", creationDate ?? ""),
              headerRows = makeDeepCopy(SupplierPricingExcelTemplateHeaderRows_AoA),
              
              //construct supplier template AOA
              excelData = data.map(x => {
                  const inputRow = x as SupplierPricingTemplateHistoryApiResponseDataRow,
                        outputRow: string[] = [
                            inputRow.PriceListNumber ?? "",
                            inputRow.VendorNumber ?? "",
                            inputRow.VendorName ?? "",
                            inputRow.PriceBeginDate ?? "",
                            inputRow.PriceEndDate ?? "",
                            inputRow.PriceDateType ?? "",
                            inputRow.Region ?? "",
                            inputRow.Sku ?? "",
                            inputRow.Gtin ?? "",
                            inputRow.ProductDescription ?? "",
                            inputRow.Uom ?? ""
                        ];

                        const rowsBeforeBrackets = outputRow.length;

                        for (let i = 1; i <= 10; i++) {
                            const nextBracketStartIndex = rowsBeforeBrackets + ((3 * i) - 3);
                            outputRow[nextBracketStartIndex] = inputRow.PriceBrackets
                                                                       ?.find(x => x.TemplateNumber === i.toString())
                                                                       ?.Id ?? "";
                            outputRow[nextBracketStartIndex + 1] = inputRow.PriceBrackets
                                                                           ?.find(x => x.TemplateNumber === i.toString())
                                                                           ?.Price ?? "";
                            outputRow[nextBracketStartIndex + 2] = inputRow.PriceBrackets
                                                                           ?.find(x => x.TemplateNumber === i.toString())
                                                                           ?.QtyDescription ?? "";
                        }

                        outputRow.push(inputRow.ProductLine ?? "");
                        return outputRow;
              });
        writeExcelFileFromAoA(excelData, outFileName, headerRows);
    };

    const convertProductLineFloatTemplate = (document: TemplateHistoryApiResultObject) => {
        const cosmosDocument = document as ProductLineFloatTemplateHistoryApiResultObject,
        { FileName: fileName, Data: data, Created: creationDate } = cosmosDocument,
        outFileName = generateExcelFileName(fileName ?? "", creationDate ?? ""),
        headerRows = makeDeepCopy(ProductLineFloatTemplateHeaderRows_AoA),

        //construct special fixed template AOA
        excelData = data.map(x => {
            const inputRow = x as ProductLineFloatTemplateHistoryApiResponseDataRow,
                  outputRow: string[] = [
                      inputRow.CustomerNumber ?? "",
                      inputRow.CustomerShiptoNumber ?? "",
                      inputRow.CustomerName ?? "",
                      inputRow.ProductLine ?? "",
                      inputRow.ProductLineDescription ?? "",
                      inputRow.ProductLineCategory ?? "",
                      inputRow.Contract ?? "",
                      inputRow.ContractName ?? "",
                      inputRow.Price ?? "",
                      inputRow.PriceUom ?? "",
                      inputRow.PricePercentage ?? "",
                      inputRow.PriceBeginDate ?? "",
                      inputRow.PriceEndDate ?? "",
                      inputRow.Employee ?? "",
                      inputRow.JobType ?? "",
                      inputRow.Supplier ?? "",
                      inputRow.Comment ?? "",
                      inputRow.Promotion ?? "",
                      inputRow.Adjustment ?? "",
                      inputRow.AutoAdjustment ?? "",
                      inputRow.AdjustmentType ?? "",
                      inputRow.AdjustmentAmount ?? "",
                      inputRow.AdjustmentPercent ?? "",
                  ];

            return outputRow;
        });
        writeExcelFileFromAoA(excelData, outFileName, headerRows);
    };

    const convertSpecialFixedTemplate = (document: TemplateHistoryApiResultObject) => {
        const cosmosDocument = document as SpecialFixedTemplateHistoryApiResultObject,
        { FileName: fileName, Data: data, Created: creationDate } = cosmosDocument,
        outFileName = generateExcelFileName(fileName ?? "", creationDate ?? ""),
        headerRows = makeDeepCopy(SpecialFixedTemplateHeaderRows_AoA),

        //construct special fixed template AOA
        excelData = data.map(x => {
            const inputRow = x as SpecialFixedTemplateHistoryApiResponseDataRow,
                  outputRow: string[] = [
                      inputRow.CustomerNumber ?? "",
                      inputRow.CustomerShiptoNumber ?? "",
                      inputRow.CustomerName ?? "",
                      inputRow.Item ?? "",
                      inputRow.ItemDescription ?? "",
                      inputRow.Manufacturer ?? "",
                      inputRow.Contract ?? "",
                      inputRow.ContractName ?? "",
                      inputRow.Price ?? "",
                      inputRow.PriceUom ?? "",
                      inputRow.PriceBeginDate ?? "",
                      inputRow.PriceEndDate ?? "",
                      inputRow.Employee ?? "",
                      inputRow.JobType ?? "",
                      inputRow.Supplier ?? "",
                      inputRow.Comments ?? "",
                      inputRow.Promotion ?? "",
                      inputRow.NationalAccount ?? "",
                      inputRow.FloatFlag ?? "",
                      inputRow.Adjustment ?? "",
                      inputRow.AutoAdjustment ?? "",
                      inputRow.Adjustment1Type ?? "",
                      inputRow.Adjustment1Amount ?? "",
                      inputRow.Adjustment1Percent ?? "",
                      inputRow.Adjustment2Type ?? "",
                      inputRow.Adjustment2Amount ?? "",
                      inputRow.Adjustment2Percent ?? "",
                      inputRow.Adjustment3Type ?? "",
                      inputRow.Adjustment3Amount ?? "",
                      inputRow.Adjustment3Percent ?? ""
                  ];

            return outputRow;
        });
        writeExcelFileFromAoA(excelData, outFileName, headerRows);
    };

    const convertSupplierPricingTemplateMissingItems = (missingItems: SupplierPricingTemplateMissingItemsApiResponse) => {
        const items = missingItems.data?.missingItemData;
        const missingItemsFileName = "MissingItems_" + new Date().toISOString().split('T')[0] + ".xlsx";

        if (!Array.isArray(items) || items.length === 0) {
            alert("No missing items found or data is not in the expected format.");
            return;
        }

        const headerRows = [
            ["Product Line", "Item Number", "MFG Number", "GTIN", "Item Description"]
        ];

        // Map each item to ensure correct format
        const excelData = items.map((item: string[]) => [
            item[0], // Product Line
            item[1], // Item Number
            item[2], // MFG Number
            item[3], // GTIN
            item[4]  // Item Description
        ])
        .sort((a, b) => a[0].localeCompare(b[0]));            

        // Combine headers and data into a single array for export
        const excelContent = [...headerRows, ...excelData];

        writeExcelFileFromAoA(excelContent, missingItemsFileName);
        return null;
    };

    const generateExcelFileName = (fileName: string, creationDate?: string) => {
        const date = creationDate ? new Date(Date.parse(creationDate)) 
                                  : new Date(),
              dateString = dateFormatter.YYYYMMDD(
                { year: date.getFullYear(), 
                  month: date.getMonth() + 1, 
                  day: date.getDate() }
              );
              
        if (!fileName) 
            return `${dateString}_SupplierListPricing.xlsx`;

        const parsedFileName = commonRegex.fileExtension.exec(fileName)?.groups;
        return parsedFileName ? `${dateString}_${parsedFileName["fileName"]}.xlsx` 
                              : `${dateString}_${fileName}.xlsx`;
    };

    const writeExcelFileFromAoA = (data: any[][], fileName: string, headers?: string[][]) => {
        const shouldSkipHeaders = headers?.length !== 0,
              workbook = XLSX.utils.book_new(),
              originRow = (headers?.length ?? 0) + 1,
              worksheet = XLSX.utils.aoa_to_sheet([[]]);

        if (headers && shouldSkipHeaders) {
            for (const [index, headerRow] of headers.entries()) {
                XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: `A${index + 1}`});
            }
        }
        XLSX.utils.sheet_add_aoa(worksheet, data, { origin: `A${originRow}` });

        // if (headers && headers?.length !== 0 && headers[0].length) {
        //     worksheet["!merges"] = (() => {
        //         const merges = [],
        //               headerRows = headers.length;
        //         for (let i = 0; i < headers[0].length; i++) {
        //             merges.push({ s: {c: i, r: 0}, e: {c: i, r: headerRows - 1 } });
        //         }
        //         return merges;
        //     })();
        // }

        XLSX.utils.book_append_sheet(workbook, worksheet, "Supplier List Pricing Template");
        XLSX.writeFileXLSX(workbook, fileName, { type: "buffer" });
    }

    return { convertSupplierPricingTemplate, 
             convertProductLineFloatTemplate,
             convertSpecialFixedTemplate, 
             convertSupplierPricingTemplateMissingItems };
}
