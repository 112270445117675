export type SpecialFixedPricingExcelTemplate = {
    CUSTOMERNUMBER: string | null,
    SHIPTO: string | null,
    NAME: string | null,
    ITEMNUMBER: string | null,
    DESCRIPTION: string | null,
    MANUFACTURER: string | null,
    CONTRACTNUMBER: string | null,
    CONTRACTNAME: string | null,
    CONTRACTDESCRIPTION: string | null,
    PRICE: string | null,
    UOM: string | null,
    BEGINDATE: string | null,
    ENDDATE: string | null,
    EMPLYEE: string | null, //Misspelled in original template
    EMPLOYEE: string | null,
    JOBTYPE: string | null,
    SUPPLIERRESPONSIBLE: string | null,
    COMMENTS: string | null,
    PROMOFLAG: string | null,
    NATIONALACCT: string | null, //Deprecated field, ignored
    FLOATFLAG: string | null,
    "ADJY/N": string | null,
    "AUTOADJY/N": string | null,
    ADJ1TYPE: string | null,
    ADJ1AMT: string | null,
    "ADJ1%": string | null,
    ADJ2TYPE: string | null,
    ADJ2AMT: string | null,
    "ADJ2%": string | null,
    ADJ3TYPE: string | null,
    ADJ3AMT: string | null,
    "ADJ3%": string | null,
}

export const SpecialFixedPricingExcelTemplate_Default = {
    CUSTOMERNUMBER: "",
    SHIPTO: "",
    NAME: "",
    ITEMNUMBER: "",
    DESCRIPTION: "",
    MANUFACTURER: "",
    CONTRACTNUMBER: "",
    CONTRACTNAME: "",
    CONTRACTDESCRIPTION: "",
    PRICE: "",
    UOM: "",
    BEGINDATE: "",
    ENDDATE: "",
    EMPLYEE: "",
    EMPLOYEE: "",
    JOBTYPE: "",
    SUPPLIERRESPONSIBLE: "",
    COMMENTS: "",
    PROMOFLAG: "",
    NATIONALACCT: "", //Deprecated field, ignored
    FLOATFLAG: "",
    "ADJY/N": "",
    "AUTOADJY/N": "",
    ADJ1TYPE: "",
    ADJ1AMT: "",
    "ADJ1%": "",
    ADJ2TYPE: "",
    ADJ2AMT: "",
    "ADJ2%": "",
    ADJ3TYPE: "",
    ADJ3AMT: "",
    "ADJ3%": "",
} as SpecialFixedPricingExcelTemplate;
