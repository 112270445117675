import { ExcelTemplateTypes, ProductLineFloatTemplateSearchTypes } from "enums";
import { useInOutTransition } from "hooks";
import SearchRadioInput from "./SearchRadioInput";

interface Props {
    searchType: string,
    updateHandler: (x: ProductLineFloatTemplateSearchTypes) => void,
    selectedTemplateType: ExcelTemplateTypes
}

export default function ProductLineFloatTemplateSearchOptions(props: Props) {
    const { searchType, updateHandler, selectedTemplateType } = props,
          shouldRender = selectedTemplateType === ExcelTemplateTypes.FloatProductLine,
          { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender, 0, 275);
    return (isNotTransitionedOut && 
        <>
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="customerId" 
                              labelText="Customer Id"
                              checkedCondition={searchType === ProductLineFloatTemplateSearchTypes.CustomerId}
                              onClick={() => updateHandler(ProductLineFloatTemplateSearchTypes.CustomerId)} />
            
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="customerName" 
                              labelText="Customer Name" 
                              checkedCondition={searchType === ProductLineFloatTemplateSearchTypes.CustomerName}
                              onClick={() => updateHandler(ProductLineFloatTemplateSearchTypes.CustomerName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineNumber" 
                              labelText="Product Line Number" 
                              checkedCondition={searchType === ProductLineFloatTemplateSearchTypes.ProductLineNumber}
                              onClick={() => updateHandler(ProductLineFloatTemplateSearchTypes.ProductLineNumber)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineName" 
                              labelText="Product Line Name" 
                              checkedCondition={searchType === ProductLineFloatTemplateSearchTypes.ProductLineName}
                              onClick={() => updateHandler(ProductLineFloatTemplateSearchTypes.ProductLineName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="contractNumber" 
                              labelText="Contract Number" 
                              checkedCondition={searchType === ProductLineFloatTemplateSearchTypes.ContractNumber}
                              onClick={() => updateHandler(ProductLineFloatTemplateSearchTypes.ContractNumber)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="contractName" 
                              labelText="Contract Name" 
                              checkedCondition={searchType === ProductLineFloatTemplateSearchTypes.ContractName}
                              onClick={() => updateHandler(ProductLineFloatTemplateSearchTypes.ContractName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineCategoryNumber" 
                              labelText="Product Category Number" 
                              checkedCondition={searchType === ProductLineFloatTemplateSearchTypes.ProductLineCategoryNumber}
                              onClick={() => updateHandler(ProductLineFloatTemplateSearchTypes.ProductLineCategoryNumber)} />
            
        </>
    );
}
