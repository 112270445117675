import { GridCellProps } from "@progress/kendo-react-grid";
import TemplateButton from "components/Buttons/TemplateButton";
import { ExcelTemplateTypes } from "enums";
import "./TemplateHistoryActionCell.scss";

interface ActionCellProps {
    props: GridCellProps,
    templateType: ExcelTemplateTypes,
    onClick: (templateType: ExcelTemplateTypes, id: string) => void,
    btnIcon?: any; 
    text?: string; 
}

export default function TemplateHistoryActionCell(cellProps: ActionCellProps) {
    const { onClick: callback, props, btnIcon, text = "", templateType } = cellProps,
          downloadClickHandler = async () => {
              await callback(templateType, props.dataItem["Id"] ?? "");
          };
    return (
        <td className="historyActionCellContainer">
            <div className={"historyActionsCell"}>
                <TemplateButton className="downloadIconButton"
                    onClick={downloadClickHandler}
                    btnIcon={btnIcon}
                    text={""} />
            </div>
            <p>{text}</p>
        </td>
    )
}
