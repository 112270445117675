import TemplateButton from "components/Buttons/TemplateButton";
import { useInOutTransition } from "hooks";
import { faArrowRightToArc, faCaretDown, faCaretUp, faXmark } from "@fortawesome/pro-light-svg-icons";
import { ValidationApiTemplateLevelError } from "types";
import { TemplateLevelErrorKeys } from "enums";
import { useMemo, useState } from "react";
import { makeDeepCopy } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TemplateApprovePanel.scss";
import { faFloppyDiskCircleXmark } from "@fortawesome/pro-solid-svg-icons"; 

interface Props {
    dismissCallback: (x: boolean) => void,
    render: boolean,
    details: ValidationApiTemplateLevelError[],
    onDownloadMissingItems: (approvePanelDetails: ValidationApiTemplateLevelError[]) => Promise<void>;
}

interface IncompleteProductLineData {
    message: string,
    detailListHeader: string,
    productLine: number,
    detailListItems: string[]
}

interface CollapsableProps {
    message: string,
    header?: string,
    content?: string[]
}

export default function TemplateApprovePanel(props: Props) {
    const { dismissCallback, details, render: shouldRender, onDownloadMissingItems } = props,
          { isNotTransitionedOut, isActive } = useInOutTransition(shouldRender),
          missingProductLineCount = details.filter(x => x.ErrorKey === TemplateLevelErrorKeys.ProductLineIncomplete).length,
          panelHeaderText = !details ? "Manual Approval Panel Header Text (if you are seeing this message, this is a bug)" 
                                     : missingProductLineCount ? `This submission does not appear to cover the entire product line${missingProductLineCount > 1 ? "s" : ""}.` 
                                                               : `This template could not be automatically approved for the following reason${details.length > 1 ? "s" : ""}...`,
          submitButtonText = missingProductLineCount ? "SUBMIT AS PARTIAL" 
                                                     : "APPROVE SUBMISSION";
    
    const displayDetails: JSX.Element[] = generateDisplayDetailList(details);

    return ( isNotTransitionedOut && 
        <div className={`templateApprovePanel ${shouldRender && isActive ? "" : "hidden"}`} data-testid="templateApprovePanel">
            <h3>{panelHeaderText}</h3>
            <div className="approvePanelDetails">
                <ul>
                    {displayDetails}
                </ul>
            </div>
            <div className="panelResponseButtons">
                <TemplateButton className="overrideButton" 
                                text={submitButtonText} 
                                btnIcon={faArrowRightToArc} 
                                onClick={() => dismissCallback(true)}></TemplateButton>
                <TemplateButton className="downloadButton" 
                                text="DOWNLOAD ITEMS" 
                                btnIcon={faFloppyDiskCircleXmark} 
                                onClick={() => onDownloadMissingItems(details)}></TemplateButton>
                <TemplateButton className="closeButton" 
                                text="CLOSE" 
                                btnIcon={faXmark} 
                                onClick={() => dismissCallback(false)}></TemplateButton>
            </div>
        </div>
    )
}

function formatIncompleteProductLineDetails(data: ValidationApiTemplateLevelError): IncompleteProductLineData {
    const productLinePattern = /product\s*line\s*:?\s*(?<lineNumber>\d+)/i,
          match = productLinePattern.exec(data.Message)?.groups,
          productLine = match ? parseInt(match["lineNumber"] ?? "0") : 0;
    
    const output = {
        message: data.Message,
        productLine: productLine,
        detailListHeader: `Missing items${productLine ? ` from product line ${productLine}` : ""}`,
        detailListItems: data.AdditionalDetails ?? []
    } as IncompleteProductLineData;

    return output;
}

function generateDisplayDetailList(details: ValidationApiTemplateLevelError[]): JSX.Element[] {
    //Splitting off missing product line item errors so that we can display counts at the top
    const missingItemDetails = !details ? [] 
                                        : details.filter(x => x.ErrorKey === TemplateLevelErrorKeys.ProductLineIncomplete 
                                                                     && x.AdditionalDetails)
                                                 .map(x => formatIncompleteProductLineDetails(x))
                                                 .sort((a, b) => a.productLine - b.productLine),
          missingItemDisplayElements = missingItemDetails.map((x, index) => {
              return (
                  <li key={`approve-panel-err-${index}`}>
                      <CollapsableTemplateError message={x.message} header={x.detailListHeader} content={x.detailListItems} />
                  </li>
              );
          });
    
    const output = [
        ...missingItemDisplayElements,
        ...details.flatMap((x, index) => {
            if (x.ErrorKey === TemplateLevelErrorKeys.ProductLineIncomplete && x.AdditionalDetails) {
                return []; //Prevent these from being added twice
            } else if (x.AdditionalDetails) {
                return [<li key={index}>{`${x.Message}, details: ${x.AdditionalDetails.join(", ")}`}</li>];
            } else 
                return [<li key={index}>{x.Message}</li>];
        })];

    return output;
}

function CollapsableTemplateError({message, header, content}: CollapsableProps): JSX.Element {
    const [expanded, setExpanded] = useState<boolean>(),
          displayContent = useMemo(() => {
              const copy = makeDeepCopy(content) as string[];
              if (copy != null && header)
                  copy.unshift(header);
              return copy;
          }, [header, content]);

    const clickHandler = () => {
        if (content) 
            setExpanded(!expanded)
    };

    return (
        <div className="collapsableTemplateError" onClick={clickHandler}>
            <button aria-expanded={expanded}>
                <span className={expanded ? "expanded" : ""}>
                    {message}<FontAwesomeIcon className="errorExpandIcon" icon={expanded ? faCaretUp : faCaretDown} />
                </span>
            </button>
            {expanded && displayContent && 
                <ul>
                    {displayContent.map((x, index) => <li key={`approve-panel-err-detail-${index}`}>{x}</li>)}
                </ul>
            }
        </div>
    );
}
