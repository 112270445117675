import { PricingTemplateHistoryApiResult, TemplateHistoryApiResultObject, TemplateHistorySearchParameters } from "types";
import { makeDeepCopy } from "utils";

export interface SearchResultsUpdate {
    type: "reset" | "update" | "downloadError" | "dismissErrors" | "continue",
    apiResult?: PricingTemplateHistoryApiResult,
    search?: TemplateHistorySearchParameters
}

export interface TemplateHistorySearchResults {
    result: any[],
    errors: string[],
    hasMoreResults: boolean,
    page: number,
    actionMessage: string,
    previousSearch?: TemplateHistorySearchParameters
    emptyResult?: boolean
}

export const TemplateHistorySearchResults_Default = { 
    result: [], 
    errors: [], 
    page: 1, 
    hasMoreResults: false, 
    actionMessage: "" 
} as TemplateHistorySearchResults;

export const combineHistoryResultSets = (initial: TemplateHistoryApiResultObject[], additional: TemplateHistoryApiResultObject[]) => {
    //We aren't able to aggregate results the way we want in the actual Cosmos query - 
    //search operations return multiple rows for each template, which we then combine 
    //on the backend using LINQ. However, we cap the number of records coming back 
    //from Cosmos to paginate extremely large result sets.

    //Thus, it is possible for the last template in a very large search result 
    //to have its associated records split by pagination. We can fix the incomplete record 
    //when the next page is queried by looking for a common template ID between the two 
    //result sets and combining both partial records.

    const idMap = new Map(),
          commonIds: string[] = [];

    initial.forEach(template => idMap.set(template.Id, template));
    additional.forEach(template => !idMap.get(template.Id) ? idMap.set(template.Id, template)
                                                           : commonIds.push(template.Id));
    if (commonIds.length) {
        //There should, in theory, only be one of these, but iterate just in case
        commonIds.forEach(id => {
            const index = initial.findIndex(x => x.Id === id),
                  existing = makeDeepCopy(initial[index]),
                  matchingIndex = additional.findIndex(x => x.Id === id),
                  corresponding = additional[matchingIndex];
            initial = initial.filter((_, i) => i !== index);
            for (const prop in corresponding) {
                const key = prop as keyof typeof corresponding;
                if (Array.isArray(corresponding[key])) {
                    (corresponding[key] as Array<any>)?.push(...existing[key]);
                }
            }
        });
    }
    return [...initial, ...additional];
}

//Reducer function
export const updateSearchResults = (state: TemplateHistorySearchResults, params: SearchResultsUpdate): TemplateHistorySearchResults => {
    const { type, apiResult, search: previousSearch } = params,
          defaultResult = makeDeepCopy(TemplateHistorySearchResults_Default);
    switch (type) {
        case "reset":
            return defaultResult;

        case "downloadError":
            const downloadErrorResult = { ...defaultResult,
                                          result: state.result,
                                          previousSearch: previousSearch,
                                          errors: [apiResult?.error ?? ""], 
                                          actionMessage: "If the problem persists, please report this issue." };
            if (apiResult?.statusCode) 
                downloadErrorResult.errors.push(`Response Code: ${apiResult.statusCode}`);
            return downloadErrorResult;

        case "dismissErrors":
            return { ...defaultResult, 
                     previousSearch: previousSearch, 
                     result: state.result };


        //for basic search operations
        case "update": {
            const statusCode = apiResult?.statusCode ?? 0,
                  nextPage = apiResult?.response?.MoreResults ? (apiResult?.response?.Page ?? 0) + 1
                                                              : 1;
            switch (true) {
                case statusCode >= 200 && statusCode < 300 && statusCode !== 204:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             result: apiResult?.response?.Results ?? [],
                             page: nextPage,
                             hasMoreResults: apiResult?.response?.MoreResults ?? false };
                case statusCode === 204:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             emptyResult: true };
                case statusCode >= 400 && statusCode < 500:
                    return { ...defaultResult,  
                             previousSearch: previousSearch,
                             errors: [apiResult?.error ?? `HTTP Error: ${statusCode}`],
                             actionMessage: "If the problem persists, please report this issue." };
                case statusCode === 502:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             errors: [apiResult?.error ?? "502 Bad Gateway", 
                                      "This error usually means that PriceFx is down, but also may indicate a problem " + 
                                      "with your local network connection."], 
                             actionMessage: "Please try again later." };
                case statusCode === 503:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             errors: [apiResult?.error ?? "503 Service Unavailable"],
                             actionMessage: "Please try again later."};
                default:
                    return { ...defaultResult,  
                             previousSearch: previousSearch,
                             errors: [apiResult?.error ?? `HTTP Error: ${statusCode}`],
                             actionMessage: "If the problem persists, this may require developer attention." };
            }
        }

        //for paginated search operations with very large result sets, 
        //fetch next set of results and combine with previous
        case "continue": {
            const statusCode = apiResult?.statusCode ?? 0,
                  nextPage = apiResult?.response?.MoreResults ? (apiResult?.response?.Page ?? 0) + 1
                                                              : 1,
                  apiResultSet = apiResult?.response?.Results ?? [];
            switch (true) {
                case statusCode >= 200 && statusCode < 300 && statusCode !== 204:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             result: combineHistoryResultSets(state.result, apiResultSet),
                             page: nextPage,
                             hasMoreResults: apiResult?.response?.MoreResults ?? false };
                case statusCode === 204:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             result: state.result };
                case statusCode >= 400 && statusCode < 500:
                    return { ...defaultResult,  
                             previousSearch: previousSearch,
                             result: state.result,
                             errors: [apiResult?.error ?? `HTTP Error: ${statusCode}`], 
                             actionMessage: "If the problem persists, please report this issue." };
                case statusCode === 502:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             result: state.result,
                             errors: [apiResult?.error ?? "502 Bad Gateway", 
                                      "This error usually means that PriceFx is down, but also may indicate a problem " + 
                                      "with your local network connection."], 
                             actionMessage: "Please try again later." };
                case statusCode === 503:
                    return { ...defaultResult, 
                             previousSearch: previousSearch,
                             result: state.result,
                             errors: [apiResult?.error ?? "503 Service Unavailable"],
                             actionMessage: "Please try again later."};
                default:
                    return { ...defaultResult,
                             previousSearch: previousSearch,  
                             result: state.result,
                             errors: [apiResult?.error ?? `HTTP Error: ${statusCode}`],
                             actionMessage: "If the problem persists, this may require developer attention." };
            }
        }
    }
};
