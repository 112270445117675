
import { useMemo, useState } from "react";
import { Grid, 
         GridColumn, 
         GridDataStateChangeEvent, 
         GridFilterOperators } from "@progress/kendo-react-grid";
import { process, 
         State, 
         DataResult, 
         FilterDescriptor, 
         CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { ExcelTemplateTypes } from "enums";
import { useInOutTransition } from "hooks";
import { DateRange,
         TextExpandPanelProps,
         TemplateHistoryApiResultObject, } from "types";
import { TemplateHistoryActionCell, 
         TemplateHistoryStatusFilter,
         TemplateHistoryDateRangeFilter, 
         TemplateHistoryExpandableTextCell,
         TemplateHistoryStatusCell } from "components";
import TemplateHistoryTextExpandPanel from "./Panels/TemplateHistoryTextExpandPanel";
import { useHistoryTableDataProcessingService } from "services";
import { faFloppyDiskCircleXmark, faSave } from "@fortawesome/pro-solid-svg-icons"; 
import "./TemplateHistorySearchResultsTable.scss";

interface HistoryTableProps {
    shouldRender: boolean,
    className?: string,
    templateType: ExcelTemplateTypes,
    tableData: TemplateHistoryApiResultObject[],
    isComplete?: boolean,
    onDownload: (type: ExcelTemplateTypes, id: string) => Promise<void>
    onDownloadMissingItems: (id: string) => Promise<void>
}

export default function TemplateHistorySearchResultsTable(props: HistoryTableProps) {
    const { shouldRender, tableData, templateType, 
            className, isComplete, onDownload, onDownloadMissingItems } = props,
          { defaults, preprocess, fixAllCaps, cellListFormatter, aggregators } = useHistoryTableDataProcessingService();

    const [dataState, setDataState] = useState<State>(defaults.initialDataState),
          [expandedTextProps, setExpandedTextProps] = useState<TextExpandPanelProps>(defaults.textExpandProps),
          [statusFilterValues, setStatusFilterValues] = useState<string[]>([]),
          [createdDateRange, setCreatedDateRange] = useState<DateRange>({minDate: null, maxDate: null});

    const { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender, 25, 500);

    //Incomplete results for large search operations will 
    //often have only partial data for the final entry.  We want to 
    //keep this in the result set (to later combine with 
    //the next page if more results are loaded), but we want to 
    //hide it from the user while it's incomplete to avoid confusion.
    const dataSnapshot = useMemo(() => {
        return (isComplete || tableData.length <= 1) ? tableData
                                                     : [...tableData].slice(0, -1);
    }, [tableData, isComplete]);

    const preprocessedTableData = useMemo(() => {
        switch (templateType) {
            case ExcelTemplateTypes.Supplier:
                return preprocess(aggregators.supplierPricingTemplateColumns(dataSnapshot));
            // case ExcelTemplateTypes.FloatItem:
            //     return preprocess(populate.itemFloatPricingTemplateColumns(dataSnapshot));
            case ExcelTemplateTypes.FloatProductLine:
                return preprocess(aggregators.productLineFloatTemplateColumns(dataSnapshot));
            case ExcelTemplateTypes.Fixed:
                return preprocess(aggregators.specialFixedPricingTemplateColumns(dataSnapshot));
            //case ExcelTemplateTypes.Extension:
            //     return preprocess(populate.extensionPricingTemplateColumns(dataSnapshot));
            default:
                return preprocess(dataSnapshot);
        }
    }, [dataSnapshot, templateType, aggregators, preprocess]);

    const gridFilterOperators = useMemo(() => {
        return {
            text: defaults.filters,
            numeric: defaults.filters,
            date: defaults.filters,
            boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
        } as GridFilterOperators
    }, [defaults.filters]);

    const onTextCellExpansion = (title: string, value: string, formatFunction?: (x: string) => string) => {
        setExpandedTextProps({...expandedTextProps, 
                              shouldRender: true,
                              dataItemDetails: { ...expandedTextProps.dataItemDetails, 
                                                 formatter: formatFunction,
                                                 fieldDisplayName: title },
                              content: value});
    };

    const onTextCellExpansionClose = () => {
        setExpandedTextProps({...expandedTextProps, 
                              shouldRender: false });
    }

    const onStatusFilterClear = () => {
        const newDataState = { ...dataState },
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor,
                        composite = x as CompositeFilterDescriptor;
                  return !(filterDescriptor.field === "Status" || 
                           composite.filters?.find(f => (f as FilterDescriptor).field === "Status"));
              }) ?? [];
              
        if (newDataState.filter) 
            newDataState.filter.filters = [...otherFilters];
        else {
            newDataState.filter = defaults.dateFilter;
            newDataState.filter.filters = [...otherFilters];
        }
        setDataState(newDataState);
        setStatusFilterValues([]);
    };

    const onStatusFilterUpdate = (showStatus: string[]) => {
        const newDataState = { ...dataState },
              newFilters = {
                  logic: "or",
                  filters: showStatus.map(x => {
                      return { field: "Status", 
                              operator: "eq", 
                              value: x } as FilterDescriptor
                  })
              } as CompositeFilterDescriptor,
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor,
                        composite = x as CompositeFilterDescriptor;
                  return !(filterDescriptor.field === "Status" || 
                           composite.filters?.find(f => (f as FilterDescriptor).field === "Status"));
              }) ?? [];
              
        if (newDataState.filter) 
            newDataState.filter.filters = [...otherFilters, newFilters];
        else {
            newDataState.filter = defaults.dateFilter;
            newDataState.filter.filters = [...otherFilters, newFilters];
        }
        setDataState(newDataState);
        setStatusFilterValues(showStatus);
    };

    const onCreatedDateFilterClear = () => {
        const newDataState = { ...dataState },
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor;
                  return !(filterDescriptor.field === "CreatedDate");
              }) ?? [];
        newDataState.filter!.filters = [...otherFilters];
        setDataState(newDataState);
        setCreatedDateRange({ minDate: null, maxDate: null });
    };

    const onCreatedDateFilterUpdate = (date: Date | null, operator: "lt" | "gt") => {
        const dateRange = { ...createdDateRange };

        if (operator === "gt")
            dateRange.minDate = date;
        else
            dateRange.maxDate = date;

        const newDataState = { ...dataState },
              newFilter = { field: "CreatedDate", 
                            operator: operator, 
                            value: date } as FilterDescriptor,
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor;
                  return !(filterDescriptor.field === "CreatedDate" && filterDescriptor.operator === operator);
              }) ?? [];
              
        if (newDataState.filter) 
            newDataState.filter.filters = [...otherFilters, newFilter];
        else {
            newDataState.filter = defaults.dateFilter;
            newDataState.filter.filters = [...otherFilters, newFilter];
        }
        setCreatedDateRange(dateRange);
        setDataState(newDataState);
    };

    return (isNotTransitionedOut && 
        <div className={`${className ?? ""} historySearchResults ${shouldRender && isActive ? "" : "hidden"}`}>
            {!(tableData?.length > 0) ? (
                <span id="noResultText">
                    <p>No results found.</p>
                </span>
            ) : (
                <Grid data={process(preprocessedTableData, dataState) as DataResult}
                      scrollable="none"
                      pageable={true}
                      sortable={true}
                      reorderable={true}
                      //resizable={true} //this is buggy
                      filterable={true}
                      filterOperators={gridFilterOperators}
                      skip={dataState.skip}
                      take={dataState.take}
                      total={preprocessedTableData.length}
                      sort={dataState.sort}
                      filter={dataState.filter}
                      {...dataState}
                      onDataStateChange={(e: GridDataStateChangeEvent) => setDataState(e.dataState)}
                >
    
                    {(templateType === ExcelTemplateTypes.Supplier && 
                        <GridColumn field="Vendors" 
                                    title="Vendor(s)"
                                    cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="Vendors"
                                            fieldDisplayName="Vendor(s)"
                                            stringFormatter={fixAllCaps}
                                            autoEllipsis={true}
                                            onClick={
                                                (title: string, content: string) => 
                                                    onTextCellExpansion(title, content, cellListFormatter)
                                            } 
                                /> }
                        />
                    )}

                    {((templateType === ExcelTemplateTypes.Fixed || templateType === ExcelTemplateTypes.FloatItem || templateType === ExcelTemplateTypes.FloatProductLine) && 
                        <GridColumn field="Customers" 
                                    title="Customer(s)"
                                    cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="Customers"
                                            fieldDisplayName="Customer(s)"
                                            stringFormatter={fixAllCaps}
                                            autoEllipsis={true}
                                            onClick={
                                                (title: string, content: string) => 
                                                    onTextCellExpansion(title, content, cellListFormatter)
                                            } 
                                /> }
                        />
                    )}
                                
                    <GridColumn field="FileName" 
                                title="File Name" 
                                cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="FileName"
                                            fieldDisplayName="File Name"
                                            autoEllipsis={true}
                                            truncateLength={20}
                                            onClick={
                                                (title: string, content: string) => 
                                                    onTextCellExpansion(title, content)
                                            } 
                                        /> 
                                }
                    />
    
                    <GridColumn field="Status" 
                                filter="text"
                                filterCell={_ => <TemplateHistoryStatusFilter statusValues={statusFilterValues}
                                                                              onFilterChange={onStatusFilterUpdate} 
                                                                              onFilterClear={onStatusFilterClear} />}
                                cell={props => <TemplateHistoryStatusCell props={props} />}
                                title="Status" 
                    />
    
                    <GridColumn field="SubmitterEmail" 
                                title="Submitted By" 
                                cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="SubmitterEmail"
                                            fieldDisplayName="Submitted By"
                                            autoEllipsis={true}
                                            truncateLength={20}
                                            onClick={
                                                (title: string, content: string) => 
                                                    onTextCellExpansion(title, content)
                                            } 
                                        /> 
                                }
                    />
    
                    <GridColumn field="CreatedDate" 
                                title="Submission Date/Time" 
                                filter="date" 
                                width={275}
                                filterCell={_ => <TemplateHistoryDateRangeFilter minDate={createdDateRange.minDate} 
                                                                                 maxDate={createdDateRange.maxDate} 
                                                                                 onFilterChange={onCreatedDateFilterUpdate} 
                                                                                 onFilterClear={onCreatedDateFilterClear} />}
                                format={"{0:F}"}
                    />

                    {((templateType === ExcelTemplateTypes.Fixed || templateType === ExcelTemplateTypes.FloatItem) &&
                        <GridColumn field="Items"
                                    title="Item(s)"
                                    cell={cellProps => 
                                            <TemplateHistoryExpandableTextCell 
                                                props={cellProps} 
                                                fieldName="Items"
                                                fieldDisplayName="Item(s)"
                                                stringFormatter={fixAllCaps}
                                                autoEllipsis={true}
                                                onClick={
                                                    (title: string, content: string) => 
                                                        onTextCellExpansion(title, content, cellListFormatter)
                                                } 
                                            /> 
                                    }
                        />
                    )}
    
                    {((templateType === ExcelTemplateTypes.Supplier || templateType === ExcelTemplateTypes.FloatProductLine) &&
                        <GridColumn field="ProductLines" 
                                    title="Product Line(s)"
                                    cell={cellProps => 
                                            <TemplateHistoryExpandableTextCell 
                                                props={cellProps} 
                                                fieldName="ProductLines"
                                                fieldDisplayName="Product Line(s)"
                                                stringFormatter={fixAllCaps}
                                                autoEllipsis={true}
                                                onClick={
                                                    (title: string, content: string) => 
                                                        onTextCellExpansion(title, content, cellListFormatter)
                                                } 
                                    /> }
                        />
                    )}

                    {((templateType === ExcelTemplateTypes.FloatProductLine) &&
                        <GridColumn field="ProductLineCategories"
                                    title="Product Categories"
                                    cell={cellProps => 
                                            <TemplateHistoryExpandableTextCell 
                                                props={cellProps} 
                                                fieldName="ProductLineCategories"
                                                fieldDisplayName="Product Categories"
                                                stringFormatter={fixAllCaps}
                                                autoEllipsis={true}
                                                onClick={
                                                    (title: string, content: string) => 
                                                        onTextCellExpansion(title, content, cellListFormatter)
                                                } 
                                            /> 
                                    }
                        />
                    )}

                    {((templateType === ExcelTemplateTypes.Fixed || 
                       templateType === ExcelTemplateTypes.FloatItem || 
                       templateType === ExcelTemplateTypes.FloatProductLine) &&
                        <GridColumn field="Contracts"
                                    title="Contract(s)"
                                    cell={cellProps => 
                                            <TemplateHistoryExpandableTextCell 
                                                props={cellProps} 
                                                fieldName="Contracts"
                                                fieldDisplayName="Contracts(s)"
                                                stringFormatter={fixAllCaps}
                                                autoEllipsis={true}
                                                onClick={
                                                    (title: string, content: string) => 
                                                        onTextCellExpansion(title, content, cellListFormatter)
                                                } 
                                            /> 
                                    }
                        />
                    )}
                    
                    {(templateType === ExcelTemplateTypes.Supplier && 
                        <GridColumn field="IsComplete" 
                                    width={150}
                                    title="Complete Product Line(s)" 
                                    filter="boolean"
                                    cell={(cellProps) => {
                                        // Check the value of IsComplete
                                        const isComplete = cellProps.dataItem.IsComplete;

                                        return (
                                            <td>
                                                {!isComplete ? (
                                                    <div style={{ display: 'flex'}}>
                                                        <div style={{ margin: 'auto' }}>
                                                            <TemplateHistoryActionCell
                                                                props={cellProps}
                                                                templateType={templateType}
                                                                onClick={(_, id) => onDownloadMissingItems(id)}
                                                                btnIcon={faFloppyDiskCircleXmark}
                                                                text="Missing Items"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{ display: 'flex', textAlign: 'center' }}>
                                                            <p style={{ margin: 'auto' }}>Complete</p>
                                                    </div>
                                                )}
                                            </td>
                                        );
                                    }}
                        />
                    )}
                    
                    <GridColumn field="ChangeNotes" 
                                title="Change Notes" 
                                cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="ChangeNotes"
                                            fieldDisplayName="Change Notes"
                                            autoEllipsis={true}
                                            onClick={onTextCellExpansion} 
                                /> }
                    />
    
                    <GridColumn field="Id"
                                filterable={false}
                                title="Save Excel File"
                                cell={(cellProps) => {

                                    return (
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ margin: 'auto' }}>
                                                    <TemplateHistoryActionCell
                                                        props={cellProps}
                                                        templateType={templateType}
                                                        onClick={onDownload}
                                                        btnIcon={faSave}
                                                        text="Download"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    );
                                }}
                    />

                </Grid>
            )}
            <TemplateHistoryTextExpandPanel shouldRender={expandedTextProps.shouldRender}
                                            dataItemDetails={expandedTextProps.dataItemDetails}
                                            content={expandedTextProps.content} 
                                            dismissCallback={onTextCellExpansionClose}
            />

        </div>
    )
}
