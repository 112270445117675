import { ValidationService,
         ClientValidationResult,
         PricingExcelApiResult,
         FloatItemPricingExcelApiValidationRequest,
         FloatItemPricingApiValidationRequestData,
         FloatItemPricingExcelApiValidationResponse,
         FloatItemPricingExcelDataRow,
         FloatItemPricingExcelTemplate,
         ExcelPropertyInfo,
         DateDetails,
         DateMetadata, 
         ExcelViewerErrorData } from "types";
import { ClientValidationError, ClientValidationErrorType } from "errors";
import { commonRegex, dateFormatter } from "utils";
import { FloatItemPricingExcelColumnHeaders, 
         FloatItemTemplateErrorKeys, 
         //SpecialPricingAdjustmentCodes, 
         YesOrNo } from "enums";
import { useSecurityService, useDateCheckerService, useExcelValidationHelpers } from "services";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export function useFloatItemPricingTemplateValidationService(id: string, fileName: string, changeNotes: string, postValidationCallback?: (args: any[]) => void): ValidationService {

    let excelViewerErrorData: ExcelViewerErrorData[] = [];

    const { getAuthHeader, getUserEmail } = useSecurityService();

    const { checkValidDay, 
            checkValidMonth, 
            checkValidYear } = useDateCheckerService();
    
    const { checkRequiredField, 
            checkExcelHeaders,
            standardizePropertyNames,
            trimValuesLeadingAndTrailingWhitespace,
            tryParseInt: tryParseIntGeneric } = useExcelValidationHelpers();

    const tryParseInt = (prop: ExcelPropertyInfo) => tryParseIntGeneric(FloatItemPricingExcelColumnHeaders, prop);

    const apiUrl = process.env.REACT_APP_SALES_PRICINGIMPORT_FLOAT_ITEM_PRICING_VALIDATION_API, 
          maxPricingDecimalPlaces = 4,
          regex = { dateFormat: commonRegex.dateFormats.mmddyyyy,
                    aboveMaxPrecision: commonRegex.maxDecimalPrecision(maxPricingDecimalPlaces),
                    hasSpaces: commonRegex.hasSpaces,
                    allNines: commonRegex.allNines };

    //Sends a POST request containing uploaded and client-side validated excel data to Sales.PricingImport
    const postToValidationApi = async (clientValidatedExcel: FloatItemPricingExcelDataRow[]) => {
        const outboundModel: FloatItemPricingExcelApiValidationRequest = await convertToRequestModel(clientValidatedExcel);
        try {
            const config: AxiosRequestConfig = { headers: await getAuthHeader() },
                  response = await axios.post(`${apiUrl}`, outboundModel, config),
                  status = response.status;
            return { response: response as FloatItemPricingExcelApiValidationResponse, 
                     postError: "",
                     statusCode: status } as PricingExcelApiResult;
        } catch(ex: any) {
            const error = ex as AxiosError,
                  status = error.response?.status ?? 0;
            return { response: error.response as FloatItemPricingExcelApiValidationResponse, 
                     postError: error.message ? error.message : "Error posting to validation API.",
                     statusCode: status } as PricingExcelApiResult;
        }
    };

        
    const doClientSideValidation = async (excel: FloatItemPricingExcelTemplate[] | null, skipNumHeaders: number) => {
        let errText: string = "";
        const errorList: ExcelViewerErrorData[] = [],
              panelErrors: string[] = [],
              validFlags = Object.values<string>(YesOrNo);
        //      validAdjTypes = Object.keys(SpecialPricingAdjustmentCodes)
        //                            .filter(x => !isNaN(Number(x)));

        excelViewerErrorData = [];
        const extraHeaderValidation = () => {
            //Checking for data in a few fields of the first data row to ensure that this isn't a blank template
            if (!excel) return;
            if (skipNumHeaders < 1) {
                if ( !( excel[0]["CUSTOMERNUMBER"] 
                     || excel[0]["ITEMNUMBER"] 
                     || excel[0]["EMPLYEE"]
                     || excel[0]["BEGINDATE"]
                     || excel[0]["ENDDATE"]
                     || excel[0]["UOM"]
                     || excel[0]["SUPPLIERRESPONSIBLE"]
                     || excel[0]["PRICE"]
                     || excel[0]["ADJY/N"]   
                     || excel[0]["JOBTYPE"] ) )
                {
                    throw new ClientValidationError(ClientValidationErrorType.NoData, 
                                                    "Excel file does not appear to contain any valid data rows.", 
                                                    null);
                }
            }
        };
        checkExcelHeaders(excel, skipNumHeaders, extraHeaderValidation);

        //Null suppression because the above call to checkExcelHeaders will 
        //throw a ClientValidationError on null or empty excel data, so there's no need for another check.
        const excelData = convertToValidationModel(excel!, skipNumHeaders);

        //Loop through rows.  
        for (const [index, row] of excelData.entries()) {
            //1 to skip the header (keys) row, 1 to offset zero-indexing
            const rowNumber = index + 2 + skipNumHeaders;

            await doDateValidation(errorList, row, rowNumber);

            for (const prop of Object.getOwnPropertyNames(row)) {
                const thisCellValue = (`${row[prop as keyof FloatItemPricingExcelDataRow] ?? ""}`).trim(),
                      propNameKey = prop as keyof typeof FloatItemPricingExcelColumnHeaders,
                      propColumnName = `column "${FloatItemPricingExcelColumnHeaders[propNameKey]}"`,
                      errorKey = prop as keyof typeof FloatItemTemplateErrorKeys;
                switch (prop) {
                    //case "nationalAccount":
                    case "autoAdjust":
                    case "adjust":
                    case "supplierResponsible":
                    case "promoFlag":
                        if (!thisCellValue) {
                            row[prop] = YesOrNo.No;
                            break;
                        }
                        else {
                            const flagValue = (`${thisCellValue}`).toUpperCase();
                            if (validFlags.includes(flagValue)) {
                                row[prop] = flagValue;
                            } else {
                                errText = `Invalid value "${thisCellValue}" for ${propColumnName}, this field can only include ` + 
                                          `one of the following values: ${validFlags.join(", ")}`;
                                errorList.push(new ExcelViewerErrorData(rowNumber, FloatItemTemplateErrorKeys[errorKey], [errText]));
                            }
                        }
                        break;
                    case "customerNumber":
                    case "customerShipto":
                    case "itemNumber":
                            const thisPropInfo = { propName: propNameKey,
                                                   value: thisCellValue,
                                                   dataRow: rowNumber,
                                                   errorKey: errorKey,
                                                   log: errorList } as ExcelPropertyInfo;
                            if (!checkIfRequiredFieldIsNotEmpty(thisPropInfo)) {
                                break;
                            } else if (thisCellValue !== "" && parseInt(thisCellValue) < 0) {
                                errText = `Invalid value "${thisCellValue}" for ${propColumnName}, cannot be a negative number.`;
                                errorList.push(new ExcelViewerErrorData(rowNumber, FloatItemTemplateErrorKeys[errorKey], [errText]));
                            }
                        break;
                    case "price":
                    case "adjustAmount":
                    case "adjustPercentage":
                        if (regex.aboveMaxPrecision.test(thisCellValue)) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, this field can have no more ` + 
                                      `than ${maxPricingDecimalPlaces} decimal places.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, FloatItemTemplateErrorKeys[errorKey], [errText]));
                        }
                        break;
                    case "employee":
                    case "jobType":
                        if (thisCellValue !== "" && parseInt(thisCellValue) < 0) {
                            errText = `Invalid value "${thisCellValue}" for ${propColumnName}, cannot be a negative number.`;
                            errorList.push(new ExcelViewerErrorData(rowNumber, FloatItemTemplateErrorKeys[errorKey], [errText]));
                        }
                        break;
                    // case "adjustmentType":
                    //     if (!(parseInt(thisCellValue) in SpecialPricingAdjustmentCodes)) {
                    //         errText = `Invalid value "${thisCellValue}" for ${propColumnName}, ` + 
                    //                   `valid options: ${validAdjTypes.join(", ")}.`;
                    //         errorList.push(new ExcelViewerErrorData(rowNumber, FloatItemTemplateErrorKeys[errorKey], [errText]));
                    //     }
                    //    break;
                }
            }
        }

        //When we're done looping through rows
        if (postValidationCallback) postValidationCallback([...excelViewerErrorData, ...errorList]);
        errorList.forEach(x => panelErrors.push(`Data row ${x.row} - ${x.info.join(" | ")}`));
        return { requestData: excelData, 
                 errorList: panelErrors, 
                 errorCount: panelErrors.length } as ClientValidationResult;
    };

    //Strips spaces and lower case letters out of Excel template property names
    const standardizeExcelPropertyNameFormat = (rawExcel: any[]) => standardizePropertyNames<FloatItemPricingExcelTemplate>(rawExcel);

    //Logs error on missing required field 
    const checkIfRequiredFieldIsNotEmpty = (props: ExcelPropertyInfo) => checkRequiredField(FloatItemPricingExcelColumnHeaders, props);

    //Checks for invalid date formats, past dates, and end date before begin date
    const doDateValidation = async (errors: ExcelViewerErrorData[], model: FloatItemPricingExcelDataRow, rowNumber: number) => {
        const format: RegExp = regex.dateFormat,
              beginErrors: ExcelViewerErrorData[] = [],
              endErrors: ExcelViewerErrorData[] = [],
              begin = model.beginDate?.toString().match(format),
              end = model.endDate?.toString().match(format),
              bothDateColumns = `${FloatItemTemplateErrorKeys.beginDate}, ${FloatItemTemplateErrorKeys.endDate}`;

        const beginDate = { year: parseInt(begin?.groups?.year ?? ""),
                            month: parseInt(begin?.groups?.month ?? ""),
                            day: parseInt(begin?.groups?.day ?? "") } as DateDetails,
              endDate =   { year: parseInt(end?.groups?.year ?? ""),
                            month: parseInt(end?.groups?.month ?? ""),
                            day: parseInt(end?.groups?.day ?? "") } as DateDetails,

              beginDateMetadata = { fullDateString: model.beginDate,
                                    date: beginDate,
                                    dateType: FloatItemTemplateErrorKeys.beginDate,
                                    dateColumnName: FloatItemPricingExcelColumnHeaders.beginDate,
                                    rowNumber: rowNumber,
                                     } as DateMetadata,
              endDateMetadata =   { fullDateString: model.endDate,
                                    date: endDate,
                                    dateType: FloatItemTemplateErrorKeys.endDate,
                                    dateColumnName: FloatItemPricingExcelColumnHeaders.endDate,
                                    rowNumber: rowNumber } as DateMetadata;

        const isSameYear = beginDate.year === endDate.year;

        const flags = { sameYear: isSameYear,
                        sameMonth: (isSameYear && beginDate.month === endDate.month),
                        invalidMonth: false,
                        invalidYear: false,
                        invalidDay: false,
                        badFormat: false,
                        badBeginDate: false,
                        badEndDate: false,
                        allNinesEndDate: false };
        
        let errText: string = "";

        //priceBeginDate checks
        if (model.beginDate) {
            flags.invalidYear = !checkValidYear(beginDateMetadata, beginErrors);
            flags.invalidMonth = !checkValidMonth(beginDateMetadata, beginErrors);
            flags.invalidDay = !checkValidDay(beginDateMetadata, beginErrors, flags.invalidMonth, flags.invalidYear);
            flags.badFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;
            flags.badBeginDate = flags.badFormat;
        }

        //priceEndDate checks
        if (model.endDate) {
            flags.allNinesEndDate = regex.allNines.test(model.endDate);
            if (!flags.allNinesEndDate) {
                flags.invalidYear = !checkValidYear(endDateMetadata, endErrors);
                flags.invalidMonth = !checkValidMonth(endDateMetadata, endErrors);
                flags.invalidDay = !checkValidDay(endDateMetadata, endErrors, flags.invalidMonth, flags.invalidYear);
                if (!flags.badFormat) flags.badFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;
                flags.badEndDate = flags.badFormat;
                
                //Date range checks
                if( (flags.sameYear && beginDate.month > endDate.month)
                 || (flags.sameMonth && beginDate.day > endDate.day)
                 || (endDate.year < beginDate.year) ) {
                    flags.badEndDate = true;
                    flags.badBeginDate = true;
                    errText = `Invalid date range of "${model.beginDate}" - "${model.endDate}", ` + 
                              `reason: end date is before begin date.`
                    errors.push(new ExcelViewerErrorData(rowNumber, bothDateColumns, [errText]));
                }
            }
        }

        if (flags.badBeginDate) {
            errText = "Begin date appears not to be in correct format.";
            errors.push(new ExcelViewerErrorData(rowNumber, FloatItemTemplateErrorKeys.beginDate, [errText]));
        } else {
            //These errors are only valuable if begin date format is correct
            errors.push(...beginErrors);
        }

        if (flags.badEndDate) {
            errText = 'End date appears not to be in correct format.';
            errors.push(new ExcelViewerErrorData(rowNumber, FloatItemTemplateErrorKeys.endDate, [errText]));
        } else {
            //These errors are only valuable if end date format is correct
            errors.push(...endErrors);
        }

        if (flags.badFormat) {
            errText = `Ensure that all dates are in the correct format: MMDDYYYY`;
            errors.push(new ExcelViewerErrorData(rowNumber, bothDateColumns, [errText]));
        }

        //Because we're accepting MMDDYYYY values for months 1-9 even without a leading zero, 
        //we need to standardize the format for the validation API
        if (model.beginDate) model.beginDate = dateFormatter.MMDDYYYY(beginDate);
        if (model.endDate && !flags.allNinesEndDate) model.endDate = dateFormatter.MMDDYYYY(endDate);
    };

    //Model conversions
    const convertToValidationModel = (rawExcelTemplate: FloatItemPricingExcelTemplate[], skipNumHeaders: number) => {
        let validationModel: FloatItemPricingExcelDataRow[] = [];
        const excelTemplate = trimValuesLeadingAndTrailingWhitespace<FloatItemPricingExcelTemplate>(rawExcelTemplate);

        for (let i = skipNumHeaders; i < excelTemplate.length; i++) {
            const fieldIsEmpty = (prop: ExcelPropertyInfo) => (prop.value?.toString().trim().length ?? 0) === 0,
                  tryParseIfNotNull = (prop: ExcelPropertyInfo) => (fieldIsEmpty(prop) ? null : tryParseInt(prop)),
                  rowData = {} as FloatItemPricingExcelDataRow,
                  excelData = excelTemplate[i],
                  propInfo = { propName: "",
                               value: null,
                               dataRow: i + 2,
                               log: [] } as ExcelPropertyInfo;

            //Non-integer fields

            rowData.name = excelData.NAME ?? "";
            rowData.beginDate = excelData.BEGINDATE ?? "";
            rowData.endDate = excelData.ENDDATE ?? "";

            rowData.manufacturer = excelData.MANUFACTURER ?? "";
            rowData.description = excelData.DESCRIPTION ?? "";
            rowData.uom = excelData.UOM ?? "";

            //Defaulting this to zero seemed like maybe a bad idea
            rowData.price = excelData.PRICE;

            rowData.contractNumber = excelData.CONTRACTNUMBER ?? "";
            rowData.contractName = excelData.CONTRACTNAME ?? "";
            rowData.supplierResponsible = excelData.SUPPLIERRESPONSIBLE ?? "";
            rowData.comments = excelData.COMMENTS ?? "";

            //rowData.floatType = excelData.FLOATTYPE ?? "";
            //rowData.nationalAccount = excelData.NATIONALACCT ?? "";

            rowData.promoFlag = excelData.PROMOFLAG ?? "";
            rowData.adjust = excelData["ADJY/N"] ?? "";
            rowData.autoAdjust = excelData["AUTOADJY/N"] ?? "";
            rowData.adjustAmount = excelData.ADJAMT ?? "";
            rowData.adjustPercentage = excelData["ADJ%"] ?? "";

            //Integer fields

            //Customer number
            propInfo.propName = FloatItemTemplateErrorKeys.customerNumber;
            propInfo.value = excelData.CUSTOMERNUMBER;
            rowData.customerNumber = tryParseIfNotNull(propInfo);

            //Customer shipto
            propInfo.propName = FloatItemTemplateErrorKeys.customerShipto;
            propInfo.value = excelData.SHIPTO;
            rowData.customerShipto = tryParseIfNotNull(propInfo);

            //Item number
            propInfo.propName = FloatItemTemplateErrorKeys.itemNumber;
            propInfo.value = excelData.ITEMNUMBER;
            rowData.itemNumber = tryParseIfNotNull(propInfo);

            //Employee number
            //This was misspelled on the original template and will accept either spelling (prioritizing correct)
            const employeeProp = excelData.EMPLOYEE ? excelData.EMPLOYEE.toString().trim().length 
                                                    : 0;
            propInfo.propName = FloatItemTemplateErrorKeys.employee;
            propInfo.value = employeeProp != null && employeeProp > 0 ? excelData.EMPLOYEE : excelData.EMPLYEE;
            rowData.employee = tryParseIfNotNull(propInfo);

            //Job type
            propInfo.propName = FloatItemTemplateErrorKeys.jobType;
            propInfo.value = excelData.JOBTYPE;
            rowData.jobType = tryParseIfNotNull(propInfo);

            //Adjustment type
            propInfo.propName = FloatItemTemplateErrorKeys.adjustType;
            propInfo.value = excelData.ADJTYPE;
            rowData.adjustType = tryParseIfNotNull(propInfo);

            if (propInfo.log && propInfo.log.length > 0) {
                excelViewerErrorData.push(...propInfo.log);
            }
            validationModel.push(rowData);
        }
        return validationModel;
    };

    //Client validation model => validation API request model
    const convertToRequestModel = (validatedExcel: FloatItemPricingExcelDataRow[]) => {
        const requestTemplate = validatedExcel.map(row => 
            { 
                return { CustomerNumber: `${row.customerNumber}`,
                         CustomerShiptoNumber: `${row.customerShipto}`,
                         CustomerName: `${row.name}`,

                         ItemDescription: `${row.description}`,
                         ItemNumber: `${row.itemNumber}`,
                         ManufacturerNumber: `${row.manufacturer}`,
                         ContractNumber: `${row.contractNumber}`,
                         ContractDescription: `${row.contractName}`,
            
                         PriceAmount: `${row.price}`,
                         PriceUnitOfMeasure: `${row.uom}`,
                         PriceBeginDate: `${row.beginDate}`,
                         PriceEndDate: `${row.endDate}`,

                         EmployeeNumber: row.employee ? row.employee : 0,
                         JobType: row.jobType ? row.jobType : 0,

                         SupplierIndicator: `${row.supplierResponsible}`,
                         Comment: `${row.comments}`,
                         PromoFlag: `${row.promoFlag}`,
                         //NationalAccount: `${row.nationalAccount}`,
                         //FloatType: `${row.floatType}`,

                         AdjustmentFlag: `${row.adjust}`,
                         AutoFlag: `${row.autoAdjust}`,
                         AdjustmentType: `${row.adjustType}`,
                         AdjustmentAmount: `${row.adjustAmount}`,
                         AdjustmentPercent: `${row.adjustPercentage}`} as FloatItemPricingApiValidationRequestData;
            }
        );
        const requestModel: FloatItemPricingExcelApiValidationRequest = 
        {
            TemplateFileName: fileName,
            CosmosGUID: id,
            SubmitterEmail: getUserEmail(),
            ChangeNotes: changeNotes,
            ItemFloatPricingTemplate: requestTemplate
        };
        return requestModel;
    };
    
    //Exports
    const testingExports = { doDateValidation,
                             convertToValidationModel,
                             convertToRequestModel };

    return {
        doClientSideValidation, 
        postToValidationApi,
        standardizeExcelPropertyNameFormat,
        testingExports
    };
}
