
import "./CustomTextTag.scss";

interface CustomTextTagProps {
    id?: string,
    className?: string,
    text: string,
    italic?: boolean
}

export default function CustomTextTag(props: CustomTextTagProps) {
    const { id, className, text, italic } = props;
    return (
        <span id={id} className={`customTextTag ` + 
                                 `${italic ? "italic" : ""} ` + 
                                 ` ${className ?? ""}`}>
            {text}
        </span>
    );
}
