import { ContractNameNumber, CustomerNameId } from "./SpecialFixedTemplateHistoryApiResponse";
import { ProductLineNameNumber } from "./SupplierPricingTemplateHistoryApiResponse";
import { TemplateHistoryApiResultObject } from "./TemplateHistoryApiResult";

export type ProductLineFloatTemplateHistoryApiResultObject = 
    TemplateHistoryApiResultObject & 
    ProductLineFloatTemplateHistoryMetadata;

export type ProductLineFloatTemplateHistoryMetadata = {
    Customers: CustomerNameId[] | null,
    ProductLines: ProductLineNameNumber[] | null,
    Contracts: ContractNameNumber[] | null,
    ProductLineCategories: ProductLineCategoryNameNumber[] | null
}

export type ProductLineCategoryNameNumber = {
    ProductLineCategoryName: string | null,
    ProductLineCategoryNumber: string | null
}

export const ProductLineFloatTemplateHeaderRows_AoA = [
    [
        "Customer Number",
        "Shipto",
        "Name",
        "Product Line",
        "Description",
        "Product Line Category",
        "Contract Number",
        "Contract Name",
        "Price",
        "UOM",
        "Percent",
        "Begin Date",
        "End Date",
        "Employee",
        "Job Type",
        "Supplier Responsible",
        "Comments",
        "Promo Flag",
        "Adj Y/N",
        "Auto Adj Y/N",
        "Adj Type",
        "Adj Amt",
        "Adj %",
    ]
];
