import { PricingTemplateHistoryApiResult, TemplateHistorySearchParameters } from "types";
import { getEnumValues, prettyStringify } from "utils";
import { SearchResultsUpdate } from "./updateSearchResults";

export interface HistoryApiMethods {
    transitionStarter: (action: () => Promise<void>) => Promise<void>,
    reducer: (update: SearchResultsUpdate) => void,
    makeApiCall?: (search: TemplateHistorySearchParameters) => Promise<PricingTemplateHistoryApiResult>
}

export async function searchHistoryApi<TSearchTypeEnum extends object, THistorySearch extends TemplateHistorySearchParameters>
    ( searchSettings: TemplateHistorySearchParameters, validTypes: TSearchTypeEnum, methods: HistoryApiMethods )
    {
        const { searchType, searchPage, searchTerms, templateStatus } = searchSettings,
              { transitionStarter, reducer, makeApiCall } = methods;

        if (!makeApiCall)
            return Promise.reject("Attempted to call searchHistoryApi without specifying an API, " + 
                                  "this is a bug and should be reported.");
        
        if (`${process.env.REACT_APP_DEBUG_CONSOLE_LOGGING}` === "true") {
            console.log(`searchType: ${searchType}, searchPage: ${searchPage}, ` + 
                        `searchTerms: ${searchTerms}, templateStatus: ${templateStatus}`);
        }

        const searchByParam = getEnumValues(validTypes).find(x => `${x}` === searchType),
              params = { ...searchSettings, 
                         searchType: searchByParam } as THistorySearch;

        await transitionStarter(async () => {
            reducer({type: "reset"});
            const apiResult = await makeApiCall(params).catch(ex => {
                throw Error(`useTemplateHistoryService.getSupplierPricingTemplateHistory: ` + 
                            `params: ${prettyStringify(params)}, error: ${ex.toString()}`);
            });
            if (apiResult?.response?.Page > 1) {
                reducer({ type: "continue", apiResult: apiResult, search: searchSettings });
            } else {
                reducer({ type: "update", apiResult: apiResult, search: searchSettings });
            }
        }).catch(ex => {
            throw Error(ex.toString());
        });
}
