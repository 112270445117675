import { useState } from "react";

//SImilar to React's useTransition hook, but for an async callback.
//Same syntax as useTransition, but the transition starter 
//returns a promise (and, thus, can be awaited)
export function useAsyncTransition(): [boolean, (callback: () => Promise<void>) => Promise<void>] {
    const [inProgress, setInProgress] = useState<boolean>(false);

    const startTransition = async (action: () => Promise<void>) => {
        setInProgress(true);
        await action().catch(ex => {
            setInProgress(false);
            throw Error(ex.toString());
        });
        setInProgress(false);
    };

    return [inProgress, startTransition];
}
